/* eslint-disable no-nested-ternary, react/jsx-props-no-spreading, react/no-array-index-key */

/* prettier-ignore */
// import '../../styles/article-legacy.css';
import { RoutesProvider } from '@folklore/routes';
import ArticleLegacy from '@urbania-media/article-legacy';
import messages from '@urbania-media/article-legacy/locale/fr';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import useScrollTrigger from '../../hooks/useScrollTrigger';
import * as AppPropTypes from '../../lib/PropTypes';

import { useApi } from '../../contexts/ApiContext';

/* prettier-ignore */
import '../../styles/article-legacy.css';
import styles from '../../styles/documents/article-legacy-document.module.css';

const propTypes = {
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    rawData: PropTypes.object,
    metadata: AppPropTypes.documentMetadata,
    placeholder: PropTypes.bool,
    current: PropTypes.bool,
    entering: PropTypes.bool,
    isPreview: PropTypes.bool,
    onProgress: PropTypes.func,
    onComplete: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    rawData: null,
    metadata: null,
    placeholder: false,
    current: false,
    entering: false,
    isPreview: false,
    onProgress: null,
    onComplete: null,
    className: null,
};

function ArticleLegacyDocument({
    id,
    rawData,
    metadata,
    className,
    current,
    entering,
    isPreview,
    onProgress,
    onComplete,
}) {
    const { brands = [] } = metadata || {};
    const [brand = null] = brands || [];
    const { handle: brandHandle = null } = brand || {};

    const onScrollTrigger = useCallback(
        (trigger) => {
            if (onProgress !== null) {
                onProgress(trigger);
            }

            if (trigger === 1 && onComplete !== null) {
                onComplete();
            }
        },
        [onProgress, onComplete],
    );
    const { ref: scrollRef } = useScrollTrigger({
        disabled: !current || isPreview,
        onTrigger: onScrollTrigger,
    });

    const api = useApi();

    return (
        <IntlProvider locale="fr" messages={messages}>
            <RoutesProvider
                routes={{
                    'api.quiz.userResults': `${api.baseUrl}/legacy/${id}/quiz/user-results`,
                    'api.quiz.results': `${api.baseUrl}/legacy/${id}/quiz/results`,
                    'api.quiz.answer': `${api.baseUrl}/legacy/${id}/quiz/answer`,
                }}
            >
                <div id="article-legacy" ref={scrollRef}>
                    <ArticleLegacy
                        article={rawData}
                        className={classNames([styles.container, styles[brandHandle], className])}
                        adsDisabled={!current && !entering}
                        withoutAddons
                        withoutAds
                    />
                </div>
            </RoutesProvider>
        </IntlProvider>
    );
}

ArticleLegacyDocument.propTypes = propTypes;
ArticleLegacyDocument.defaultProps = defaultProps;

export default ArticleLegacyDocument;
